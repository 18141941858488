<template>
    <div class="attend">
        <Crumbs></Crumbs>
        <el-row class="attend-top">
            <div class="attend-top-left"></div>
            <div class="attend-top-right">
                <el-button class="attend-top-btn2" size="small" type="primary" @click="downloadExcel">导出</el-button>
            </div>
        </el-row>
        <el-row class="attend-btm">
            <table id="attend-table">
                <tbody>
                    <tr>
                        <th rowspan="4" class="table-zz">离职人员分析</th>
                    </tr>
                    <tr>
                        <th class="table-top" colspan="5">离职人员分析</th>
                    </tr>
                    <tr>
                        <th rowspan="2">司龄分析</th>
                        <th>试用期</th>
                        <th>转正且未满一年</th>
                        <th>1年-3年</th>
                        <th>3年以上</th>
                    </tr>
                    <tr>
                        <th>{{ tableData.probation }}人</th>
                        <th>{{ tableData.lessThanOne }}人</th>
                        <th>{{ tableData.oneToThree }}人</th>
                        <th>{{ tableData.moreThanThree }}人</th>
                    </tr>
                </tbody>
            </table>
        </el-row>
    </div>
</template>
<script>
export default {
    components: {
        Crumbs: () => import("@/components/Crumbs.vue"),
    },
    data() {
        return {
            tableData: []
        }
    },
    created() {
        this.getData();//获取列表
    },
    watch: {
    },
    methods: {
        // 获取列表
        getData() {
            this.$api.user.staffDepartReport()
                .then(res => { this.tableData = res.data; })
                .catch(err => { console.log(err); })
        },

        //导出
        downloadExcel() {
            this.$api.user.staffDepartReportExport()
                .then((res) => {
                    var blob = new Blob([res], { type: 'application/vnd.ms-excel' })
                    var url = URL.createObjectURL(blob)
                    var exportLink = document.createElement('a')
                    exportLink.setAttribute('download', '离职人员司龄分析.xlsx')
                    exportLink.href = url
                    document.body.appendChild(exportLink)
                    exportLink.click()
                    document.body.removeChild(exportLink) //下载完成移除元素
                    URL.revokeObjectURL(exportLink.href) //释放掉blob对象
                    this.$message({
                        message: '导出成功!',
                        type: 'success',
                    })
                }).catch((err) => {
                    console.log(err);
                })
        }
    }
}
</script>
<style scoped lang="scss">
.attend {
    padding: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px;

    .attend-top {
        margin-bottom: 5px;

        .attend-top-left {
            margin: 5px;
            height: 40px;

            .day {
                font-size: 20px;
                margin-right: 20px;
            }
        }

        .attend-top-right {
            position: relative;

            .attend-top-btn1,
            .attend-top-btn2 {
                position: absolute;
                right: 110px;
                top: -35px;
                width: 90px;
            }

            .attend-top-btn2 {
                right: 8px;
            }
        }
    }
}

.attend-btm {
    padding-left: 20px;
    width: 1600px;

    #attend-table {
        width: 100%;
        text-align: center;

        th,
        tr,
        td {
            border: 1px solid #080808;
            color: #080808;
        }

        td,
        th {
            height: 50px;
            padding: 20px;
        }

        .table-zz {
            background-color: #fde9d9;
            width: 100px;
        }

        .table-top {
            background-color: #dce6f1;
        }
    }
}
</style>